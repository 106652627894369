<template>
  <el-form-item v-if="transactionTaxForm.business.indexOf(businessCode)!=-1" :label="$t('Transaction tax')">
    <el-input size="medium" v-model="transactionTaxForm.amount" disabled>
      <template slot="prepend">₣</template>
    </el-input>
  </el-form-item>
</template>

<script>
import config from "../api/config"
export default {
  props: ['businessCode', 'transactionAmount'],
  data () {
    return {
      transactionTaxForm: { business: [] },
      /**交易税相关 */
      tt: {}
    }
  },
  watch: {
    transactionAmount (newValeue) {
      if (Number(newValeue) < this.tt.low)
        this.$set(this.transactionTaxForm, 'amount', 0)
      else
        this.$set(this.transactionTaxForm, 'amount', this.tt.amount)
    }
  },
  // computed: {
  //   aaaa () {
  //     // console.log(this.transactionAmount)
  //     return this.transactionAmount
  //   },
  // },
  methods: {
    getTransactionTax () {
      let _this = this;
      config.getTransactionTax({
        success: res => {
          if (res.code != 200 || res.data.switch != 0) return;

          let business = res.data.business ? res.data.business.split(",") : [];
          _this.$set(_this.transactionTaxForm, "business", business);
          _this.$set(_this, 'tt', res.data)
        }
      })
    }
  },
  mounted () {
    this.getTransactionTax();
  }
}
</script>

<style lang="less" scoped>
</style>