import ajax from '../assets/uilt/ajax';

export default {
  /**获取定时扣除平台维护费配置 */
  getPlaMaiFee: (req) => { return ajax.get({ url: "/back/config/get-pla-mai-fee", param: req.param, success: req.success }) },
  /**更新定时扣除平台维护费配置 */
  updatePlaMaiFee: (req) => { return ajax.post({ url: "/back/config/update-pla-mai-fee", param: req.param, success: req.success }) },

  /**获取交易税配置 */
  getTransactionTax: (req) => { return ajax.get({ url: "/back/config/get-transaction-tax", param: req.param, success: req.success }) },
  /**更新交易税配置 */
  updateTransactionTax: (req) => { return ajax.post({ url: "/back/config/update-transaction-tax", param: req.param, success: req.success }) },
}