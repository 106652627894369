import ajax from '../assets/uilt/ajax';
export default {
  /**客户开户（个人、公司） */
  openAccount: (req) => { return ajax.post({ url: "/back/customer/open-account", param: req.param, success: req.success }) },
  /**客户销户（个人、公司销户） */
  cancellationCustomer: (req) => { return ajax.post({ url: "/back/customer/cancellation-customer", param: req.param, success: req.success }) },
  /**客户账号锁定、解锁 */
  lockCustomer: (req) => { return ajax.post({ url: "/back/customer/lock-customer", param: req.param, success: req.success }) },

  /**根据账户卡号或者手机号查询出账户信息 */
  getByAccountOrPhone: (req) => { return ajax.get({ url: "/back/customer/get-by-account-or-phone", param: req.param, success: req.success }) },
  /**分页查询机构的客户 */
  getPage: (req) => { return ajax.get({ url: "/back/customer/get-page", param: req.param, success: req.success }) },
  /**查询客户登录日记记录 */
  listCustomerLog: (req) => { return ajax.get({ url: "/back/customer/list-customer-log", param: req.param, success: req.success }) },

  /**更新客户信息 */
  updateCustomerById: (req) => { return ajax.post({ url: "/back/customer/update-customer-by-id", param: req.param, success: req.success }) },

  /**检查个人、公司开户账户是否存在(code=200账户不存在；code=300002账户已经存在) */
  checkCustomerLoginName: (req) => { return ajax.get({ url: "/back/customer/check-customer-loginName", param: req.param, success: req.success }) },
  /**检查个人、公司开户手机号码是否存在(code=200号码不存在；code=300003号码已经存在) */
  checkCustomerPhoneNo: (req) => { return ajax.get({ url: "/back/customer/check-customer-phoneNo", param: req.param, success: req.success }) },





  /**【大客户相关】平台开大客户（公司）   只能是平台使用 */
  addAccount: (req) => { return ajax.post({ url: "/back/customer/add-account", param: req.param, success: req.success }) },

  /**【大客户相关】绑定客户手续费*/
  bindingCusSer: (req) => { return ajax.post({ url: "/back/customer/binding-cus-ser", param: req.param, success: req.success }) },

  /**【大客户相关】查询客户的手续费规则*/
  listCusSer: (req) => { return ajax.get({ url: "/back/customer/list-cus-ser", param: req.param, success: req.success }) },
}